import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';

import DadosAula from '../pages/DadosAula';
import ProgressoAlunos from '../pages/DadosAula/ProgressoAlunos';
import Dashboard from '../pages/Dashboard';
import AlertTemplate from '../components/AlertTemplate';
import AlterarSenha from '../pages/Auth/AlterarSenha';

const AppLayout = lazy(() => import('./../layouts/App'));
//const DefaultLayout = lazy(() => import('./../layouts/Default'));
const EditorLayout = lazy(() => import('./../layouts/Editor'));
const AuthLayout = lazy(() => import('./../layouts/Auth'));

const RankingAlunos = lazy(() => import('../pages/Dashboard/RankingAlunos'));
const HistoricoAcesso = lazy(() =>
  import('../pages/Dashboard/HistoricoAcesso')
);
const PerfilAluno = lazy(() => import('../pages/PerfilAluno'));
const InfoAula = lazy(() => import('../pages/PerfilAluno/InfoAula'));
const DadosLivro = lazy(() => import('../pages/DadosLivro'));
const Login = lazy(() => import('../pages/Auth/Login'));
const Editor = lazy(() => import('../pages/Editor'));
const Curso = lazy(() => import('../pages/Cursos/Curso'));
const Divisao = lazy(() => import('../pages/Cursos/Divisao'));
const Cursos = lazy(() => import('../pages/Cursos'));
const Subdivisao = lazy(() => import('../pages/Cursos/Subdivisao'));
const Livro = lazy(() => import('../pages/Cursos/Livro'));
const Nivel = lazy(() => import('../pages/Cursos/Nivel'));
const Subnivel = lazy(() => import('../pages/Cursos/Subnivel'));
const Search = lazy(() => import('../pages/Cursos/Search'));
const Empresa = lazy(() => import('../pages/Empresa'));
const BannerConfig = lazy(() => import('../pages/BannerConfig'));
const Banner = lazy(() => import('../pages/Banner'));
//const ClassRoom = lazy(() => import('../pages/ClassRoom'));
const Game = lazy(() => import('../pages/Game'));
const RecuperarSenha = lazy(() => import('../pages/Auth/RecuperarSenha'));
const Alunos = lazy(() => import('../pages/Alunos'));
const Responsaveis = lazy(() => import('../pages/Responsaveis'));
const EditResponsavel = lazy(() =>
  import('../pages/Responsaveis/EditResponsavel')
);
const Escolas = lazy(() => import('../pages/Escolas'));
const Parceiros = lazy(() => import('../pages/Parceiros'));
const TestVoice = lazy(() => import('../pages/TestVoice'));
const TestVoice2 = lazy(() => import('../pages/TestVoice2'));
const TestVoice3 = lazy(() => import('../pages/TestVoice3'));
const TestVoice4 = lazy(() => import('../pages/TestVoice4'));
const ElsaVoice = lazy(() => import('../pages/Elsa'));
const Professores = lazy(() => import('../pages/Professores'));
const EditProfessor = lazy(() => import('../pages/Professores/EditProfessor'));
const AddProfessor = lazy(() => import('../pages/Professores/AddProfessor'));
const EditAluno = lazy(() => import('../pages/Alunos/EditAluno'));
const RenovarAcessoAluno = lazy(() =>
  import('../pages/Alunos/RenovarAcessoAluno')
);
const AddAluno = lazy(() => import('../pages/Alunos/AddAluno'));
const EditEscola = lazy(() => import('../pages/Escolas/EditEscola'));
const AddEscola = lazy(() => import('../pages/Escolas/AddEscola'));
const EditParceiros = lazy(() => import('../pages/Parceiros/EditParceiro'));
const AddParceiros = lazy(() => import('../pages/Parceiros/AddParceiro'));
const MaterialApoio = lazy(() => import('../pages/MaterialApoio'));
const AddMaterialApoio = lazy(() =>
  import('../pages/MaterialApoio/AddMaterial')
);
const EditMaterialApoio = lazy(() =>
  import('../pages/MaterialApoio/EditMaterial')
);
const Rockspot = lazy(() => import('../pages/Rockspot'));
const Diagnostico = lazy(() => import('../pages/Diagnostico'));
const Notificacao = lazy(() => import('../pages/Notificacao'));
const Abas = lazy(() => import('../pages/Cursos/Abas'));
const Aba = lazy(() => import('../pages/Cursos/Aba'));
const AcessosProfessor = lazy(() => import('../pages/Professores/Acessos'));
const Pedidos = lazy(() => import('../pages/Pedidos'));
const PedidosShow = lazy(() => import('../pages/Pedidos/ShowAtivacaoPedidos'));
const AddPedido = lazy(() => import('../pages/Pedidos/AddPedido'));
const EditPedido = lazy(() => import('../pages/Pedidos/EditPedido'));

const Colaboradores = lazy(() => import('../pages/Colaboradores'));
const AvaliacoesConteudo = lazy(() => import('../pages/AvaliacoesConteudo'));

const EditColaborador = lazy(() =>
  import('../pages/Colaboradores/EditColaborador')
);
const ListaMateriaisDetalhes = lazy(() =>
  import('../pages/ListaMateriaisDetalhes')
);
const ListaMateriais = lazy(() => import('../pages/ListaMateriais'));

const AppRoute = ({ component: Component, layout: Layout, title, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout title={title} {...props}>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

function Router() {
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_RIGHT,
    timeout: 5000,
    // you can also just use 'scale'
    transition: transitions.SCALE,
    containerStyle: {
      zIndex: 99999999,
      textAlign: 'justify'
    }
  };
  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <BrowserRouter>
        <AppRoute path="/login" exact layout={AuthLayout} component={Login} />
        <AppRoute path="/" exact layout={AppLayout} component={Dashboard} />

        <AppRoute
          path="/ranking-alunos"
          exact
          layout={AppLayout}
          component={RankingAlunos}
          title={
            <>
              Dados de Acesso <span>- Ranking de Alunos</span>
            </>
          }
        />
        <AppRoute
          path="/historico-acesso"
          exact
          layout={AppLayout}
          component={HistoricoAcesso}
          title={
            <>
              Dados de Acesso <span>- Histórico de Acesso</span>
            </>
          }
        />
        <Route path="/perfil-aluno/:idAluno" exact component={PerfilAluno} />
        <Route
          path="/perfil-aluno/:idAluno/:idAula"
          exact
          component={InfoAula}
        />
        <Route path="/dados-livro/:idLivro" exact component={DadosLivro} />
        <Route path="/dados-aula/:idAula" exact component={DadosAula} />
        <Route
          path="/progresso-alunos-aula/:idAula"
          exact
          component={ProgressoAlunos}
        />
        <AppRoute
          path="/editor/:idAula/:idSlide"
          exact
          layout={EditorLayout}
          component={Editor}
        />
        <AppRoute
          path="/editor/:idAula"
          exact
          layout={EditorLayout}
          component={Editor}
        />
        <AppRoute
          path="/editor"
          exact
          layout={EditorLayout}
          component={Editor}
        />
        <AppRoute exact path="/cursos" layout={AppLayout} component={Cursos} />
        <AppRoute
          exact
          path="/cursos/:idCurso"
          layout={AppLayout}
          component={Curso}
        />
        <AppRoute
          exact
          path="/divisao/:idDivisao"
          layout={AppLayout}
          component={Divisao}
        />
        <AppRoute
          exact
          path="/subdivisao/:idSubdivisao"
          layout={AppLayout}
          component={Subdivisao}
        />
        <AppRoute
          exact
          path="/livro/:idLivro"
          layout={AppLayout}
          component={Livro}
        />
        <AppRoute
          exact
          path="/abas/:idLivro"
          layout={AppLayout}
          component={Abas}
        />

        <AppRoute exact path="/aba/:idAba" layout={AppLayout} component={Aba} />

        <AppRoute
          exact
          path="/nivel/:idNivel"
          layout={AppLayout}
          component={Nivel}
        />
        <AppRoute
          exact
          path="/search/:livroId"
          layout={AppLayout}
          component={Search}
        />

        <AppRoute
          exact
          path="/game"
          layout={AppLayout}
          component={Game}
          title="Game de Teste"
        />
        <AppRoute
          exact
          path="/empresa-config"
          layout={AppLayout}
          component={Empresa}
          title="Configurações do sistema"
        />
        <AppRoute
          exact
          path="/banner-config"
          layout={AppLayout}
          component={BannerConfig}
          title="Configurações do banner"
        />
        <AppRoute exact path="/banners" layout={AppLayout} component={Banner} />
        <AppRoute
          exact
          path="/subnivel/:idSubnivel"
          layout={AppLayout}
          component={Subnivel}
        />
        <AppRoute
          exact
          path="/recuperar-senha"
          layout={AuthLayout}
          component={RecuperarSenha}
        />
        <AppRoute
          exact
          path="/alterar-senha/:reset_token"
          layout={AuthLayout}
          component={AlterarSenha}
        />
        <AppRoute exact path="/alunos" layout={AppLayout} component={Alunos} />
        <AppRoute
          exact
          path="/avaliacao-conteudo"
          layout={AppLayout}
          component={AvaliacoesConteudo}
          title={'Avaliações do conteúdo'}
        />
        <AppRoute
          exact
          path="/responsaveis"
          layout={AppLayout}
          component={Responsaveis}
        />

        <AppRoute
          exact
          path="/responsavel/:id"
          layout={AppLayout}
          component={EditResponsavel}
        />

        <AppRoute
          exact
          path="/alunos/:id"
          layout={AppLayout}
          component={EditAluno}
          title="Aluno"
        />
        <AppRoute
          exact
          path="/aluno/renovar-acesso/:id"
          layout={AppLayout}
          component={RenovarAcessoAluno}
          title="Aluno"
        />

        <AppRoute
          exact
          path="/colaboradores"
          layout={AppLayout}
          component={Colaboradores}
          title="Colaboradores"
        />

        <AppRoute
          exact
          path="/colaboradores/:id"
          layout={AppLayout}
          component={EditColaborador}
          title="Colaboradores"
        />

        <AppRoute
          exact
          path="/aluno-new"
          layout={AppLayout}
          component={AddAluno}
          title="Aluno"
        />
        <AppRoute
          exact
          path="/escolas"
          layout={AppLayout}
          component={Escolas}
        />
        <AppRoute
          exact
          path="/escolas/:id"
          layout={AppLayout}
          component={EditEscola}
          title="Escola"
        />
        <AppRoute
          exact
          path="/escola-new"
          layout={AppLayout}
          component={AddEscola}
          title="Escola"
        />
        <AppRoute
          exact
          path="/parceiros"
          layout={AppLayout}
          component={Parceiros}
        />
        <AppRoute
          exact
          path="/parceiros/:id"
          layout={AppLayout}
          component={EditParceiros}
          title="Parceiro"
        />
        <AppRoute
          exact
          path="/parceiro-new"
          layout={AppLayout}
          component={AddParceiros}
          title="Parceiro"
        />
        <AppRoute
          exact
          path="/professores"
          layout={AppLayout}
          component={Professores}
        />
        <AppRoute
          exact
          path="/professores/:id"
          layout={AppLayout}
          component={EditProfessor}
          title="Professor"
        />
        <AppRoute
          exact
          path="/professores/:idProfessor/acessos"
          layout={AppLayout}
          component={AcessosProfessor}
          title="Acessos Professor"
        />
        <AppRoute
          exact
          path="/professor-new"
          layout={AppLayout}
          component={AddProfessor}
          title="Professor"
        />
        <AppRoute
          exact
          path="/test-voice"
          layout={AppLayout}
          component={TestVoice}
          title="Teste Voice Recognition"
        />
        <AppRoute
          exact
          path="/test-voice2"
          layout={AppLayout}
          component={TestVoice2}
          title="Teste Voice Recognition Browser"
        />
        <AppRoute
          exact
          path="/test-voice3"
          layout={AuthLayout}
          component={TestVoice3}
          title="Teste Voice Recognition Node"
        />
        <AppRoute
          exact
          path="/test-voice4"
          layout={AuthLayout}
          component={TestVoice4}
          title="Teste Voice Recognition Real-Time"
        />
        <AppRoute
          exact
          path="/elsa-speak"
          layout={AuthLayout}
          component={ElsaVoice}
          title="Teste Voice Recognition com o ELSA SPEAK."
        />
        <AppRoute
          exact
          path="/material-apoio"
          layout={AppLayout}
          component={MaterialApoio}
        />
        <AppRoute
          exact
          path="/material-apoio/:materialId"
          layout={AppLayout}
          component={EditMaterialApoio}
          title="Material de Apoio"
        />
        <AppRoute
          exact
          path="/new-material-apoio"
          layout={AppLayout}
          component={AddMaterialApoio}
          title="Material de Apoio"
        />
        <AppRoute
          exact
          path="/rockspot"
          layout={AppLayout}
          component={Rockspot}
          title="Rockspot"
        />

        <AppRoute
          exact
          path="/diagnostico"
          layout={AppLayout}
          component={Diagnostico}
          title="Diagnóstico"
        />
        <AppRoute
          exact
          path="/pedidos"
          layout={AppLayout}
          component={Pedidos}
          title="Pedidos de livros"
        />
        <AppRoute
          exact
          path="/pedidos/:id/ativacoes-livro"
          layout={AppLayout}
          component={PedidosShow}
          title="Pedidos de livros"
        />
        <AppRoute
          exact
          path="/pedidos/new"
          layout={AppLayout}
          component={AddPedido}
          title="Pedidos de livros"
        />
        <AppRoute
          exact
          path="/pedidos/:id/update"
          layout={AppLayout}
          component={EditPedido}
          title="Pedidos de livros"
        />

        <AlertProvider template={AlertTemplate} {...options}>
          <>
            <AppRoute
              exact
              path="/lista-materiais"
              layout={AppLayout}
              component={ListaMateriais}
              title="Lista de Materiais"
            />

            <AppRoute
              exact
              path="/lista-materiais/:id"
              layout={AppLayout}
              component={ListaMateriaisDetalhes}
              title="Lista de Materiais"
            />

            <AppRoute
              exact
              path="/send-notification"
              layout={AppLayout}
              component={Notificacao}
              title="Notificação"
            />
          </>
        </AlertProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
